<template>
  <div class="page-header">
    <div class="left">
      <div v-show="login_status === 'login'">
        <router-link to="/project/list">项目</router-link>
      </div>
    </div>
    <div class="right" v-if="login_status && login_status !== ''">
      <a href="https://biu.authing.cn" v-if="login_status === 'un_login'">登录</a>
      <span v-if="login_status === 'login'">
        <span>{{ email }}</span>
        | <a href="https://biu.authing.cn/u">个人中心</a>
        | <a
        href="https://biu.authing.cn/login/profile/logout?redirect_uri=https%3A%2F%2Fwww.biu.link%2Flogout">登出</a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  data() {
    return {
      login_status: '',
      email: '',
    }
  },

  mounted() {
    let pathname = location.pathname;
    if (pathname === '/authing-login-callback' || pathname === '/logout') {
      return;
    }

    this.email = localStorage.getItem('email');
    this.login_status = this.email ? 'login' : 'un_login'
  },

  computed: {},
}
</script>

<style scoped lang="scss">

.page-header {

  height: 41px;
  padding: 10px 20px;
  position: fixed;
  background: #eeeeee;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 999;

  .left {
    width: 50%;
  }

  .right {
    width: 50%;
    text-align: right;
  }
}

</style>
