import Vue from 'vue';
import VueRouter from 'vue-router';

import TemplateList from '../../pages/www/template/list';
import TemplateEdit from '../../pages/www/template/edit';
import ProjectList from '../../pages/www/project/list';
import ProjectEdit from '../../pages/www/project/edit';
import AuthingLoginCallback from '../../pages/www/user/login_callback';
import Logout from '@/pages/www/user/logout';

Vue.use(VueRouter);

const routes = [
  {
    path: '/project/list',
    name: 'ProjectList',
    component: ProjectList,
    meta: {
      title: 'Project List',
    },
  },
  {
    path: '/project/edit/:id',
    name: 'ProjectEdit',
    component: ProjectEdit,
    meta: {
      title: 'Edit Project',
    },
  },
  {
    path: '/project/new',
    name: 'ProjectEdit',
    component: ProjectEdit,
    meta: {
      title: 'New Project',
    },
  },
  {
    path: '/template/list',
    name: 'TemplateList',
    component: TemplateList,
    meta: {
      title: 'Template List',
    },
  },
  {
    path: '/template/edit/:id',
    name: 'TemplateEdit',
    component: TemplateEdit,
    meta: {
      title: 'Edit Template',
    },
  },
  {
    path: '/template/new',
    name: 'TemplateEdit',
    component: TemplateEdit,
    meta: {
      title: 'New Template',
    },
  },
  {
    path: '/authing-login-callback',
    name: 'authingLoginCallback',
    component: AuthingLoginCallback,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      title: 'Logout',
    },
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
