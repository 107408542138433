<template>
  <div id="app">
    <div>
      <page-header></page-header>
      <main>
        <router-view></router-view>
      </main>
      <page-footer></page-footer>
    </div>
  </div>
</template>

<script>
  import PageHeader from '../../components/www/pageheader';
  import PageFooter from '../../components/common/pagefooter';

  export default {
    name: 'app',
    components: {
      PageHeader,
      PageFooter,
    },

    computed: {},
  };
</script>

<style lang="scss">
  $fa-font-path: '../../styles/fontawesome-free-5.15.4-web/webfonts';

  @import '@/styles/fontawesome-free-5.15.4-web/scss/fontawesome';
  @import '@/styles/fontawesome-free-5.15.4-web/scss/solid';

  main {
    padding: 50px 20px 20px 20px;
    min-height: 800px;
    background: #ffffff;
  }
</style>
