<template>
  <div></div>
</template>

<script>

import api from '@/service/api_biu';

export default {
  name: 'Logout',
  data() {
    return {}
  },
  methods: {},
  async mounted() {
    const response = await api.logout();
    if (response && response.code === 0) {
      localStorage.clear();
      window.location.href = '/';
    }
  },
}
</script>
