<template>
  <div></div>
</template>

<script>

import api from '@/service/api_biu';

export default {
  name: 'AuthingLoginCallback',
  data() {
    return {}
  },
  methods: {},
  async mounted() {

    const code = jstool.getParam('code');
    const response = await api.exchange_user_info(code);
    if (response && response.code === 0) {
      const user_info = response.data;
      const email = user_info.email;
      const token = user_info.token;
      const authorization = email + ',' + token;
      localStorage.setItem('authorization', encodeURIComponent(authorization));
      localStorage.setItem('email', email);
      location.href = '/project/list';
    }
  },
}
</script>

<style scoped lang="scss">

</style>
