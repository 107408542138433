<template>
  <div class="template-list">

    <div class="top-btn">
      <Button type="primary" :to="'/template/new?project_id=' + project_id">新建模板</Button>
    </div>
    <List header="模板" border v-if="this.template_list.length > 0">
      <ListItem v-for="(template) in template_list" :key="template.id">
        <div class="name">
          <router-link :to="'/template/edit/'+template.id">{{ template.name }}</router-link>
        </div>
        <div class="abbr">
          <span>缩写：{{ template.abbr }}</span>
          <Icon type="md-copy" title="复制到粘贴板" class="icon-btn" @click="copyTextToClipboard(template.abbr)"/>
        </div>
        <template slot="action">
          <li>
            <router-link :to="'/template/edit/'+template.id">编辑</router-link>
          </li>
        </template>
      </ListItem>
    </List>

  </div>
</template>

<script>

import api from '@/service/api_biu';

export default {
  name: 'TemplateList',
  data() {
    return {
      project_id: '',
      template_list: [],
    }
  },
  async mounted() {
    this.project_id = jstool.getParam('project_id')
    let response = await api.get_template_list(this.project_id);
    if (response.code === 0) {
      this.template_list = response.data;
    }
  },
  methods: {},
}
</script>

<style scoped lang="scss">

.template-list {
  padding: 20px 50px;

  .top-btn {
    text-align: right;
    margin-bottom: 20px;
  }

  .name {
    flex: 0 0 200px;
  }

  .abbr {
    flex: auto;

    .icon-btn {
      margin-left: 10px;
      color: #2db7f5;
      cursor: pointer;
    }
  }
}

</style>
