<template>
  <div class="project-list">
    <div class="top-btn">
      <Button type="primary" to="/project/new">新建项目</Button>
    </div>
    <List header="项目" border>
      <ListItem v-for="(project) in project_list" :key="project.id">
        <div class="lang">
          <span>{{project.lang}}</span>
        </div>
        <div class="name">
          <router-link :to="'/template/list?project_id='+project.id">{{ project.name }}</router-link>
        </div>
        <div class="token">
          <span>token：{{ project.token }}</span>
          <Icon type="md-copy" title="复制到粘贴板" class="icon-btn" @click="copyTextToClipboard(project.token)"/>
        </div>
        <template slot="action">
          <li>
            <router-link :to="'/project/edit/'+project.id">编辑</router-link>
          </li>
        </template>
      </ListItem>
    </List>

  </div>
</template>

<script>

import api from '@/service/api_biu';

export default {
  name: 'ProjectList',
  data() {
    return {
      project_list: [],
    }
  },
  async mounted() {
    let response = await api.get_project_list();
    if (response.code === 0) {
      this.project_list = response.data;
    }
  },
  methods: {},
}
</script>

<style scoped lang="scss">

.project-list {
  padding: 20px 50px;

  .top-btn {
    text-align: right;
    margin-bottom: 20px;
  }

  .lang {
    flex: 0 0 60px;
    span {
      padding: 4px;
      background-color: #19be6b;
      border-radius: 4px;
      color: #ffffff;
      font-size: 12px;
    }
    margin-right: 5px;
  }

  .name {
    flex: 0 0 200px;
  }

  .token {
    flex: auto;

    .icon-btn {
      margin-left: 10px;
      color: #2db7f5;
      cursor: pointer;
    }
  }
}

</style>
