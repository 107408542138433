import Vue from 'vue';
import App from './App.vue';
import router from './router_www';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';

import fnCommon from '../../assets/js/common'
window.jstool = Object.assign({}, fnCommon);

import './mixin'

Vue.use(ViewUI);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
