<template>
  <div class="project-edit">
    <Form :model="project" label-position="top" :rules="ruleValidate">
      <FormItem label="项目名称" prop="name">
        <Input v-model="project.name" placeholder=""/>
      </FormItem>
      <FormItem label="开发语言">
        <Input v-model="project.lang" placeholder=""/>
      </FormItem>

      <FormItem>
        <Button type="primary" @click="save()">保存</Button>
      </FormItem>

    </Form>

  </div>
</template>

<script>

import api from '@/service/api_biu';

export default {
  name: 'ProjectEdit',
  data() {
    return {
      mode: '', // new or edit

      project: {
        name: '',
        lang: ''
      },
      ruleValidate: {
        name: [
          {required: true, trigger: 'blur'},
        ],
      },
    }
  },
  async mounted() {
    let project_id = this.$route.params.id;
    if (project_id) {
      let response = await api.get_project(project_id);
      if (response.code === 0) {
        this.mode = 'edit';
        this.project = response.data;
      }
    } else {
      this.mode = 'new';
    }
  },
  methods: {
    async save() {
      let res = null;
      if (this.mode === 'new') {
        res = await api.add_project(this.project);
      } else {
        res = await api.update_project(this.project);
      }
      if (res && res.code === 0) {
        this.$router.push('/project/list')
      }
    },
  },
}
</script>

<style scoped lang="scss">

.project-edit {
  padding: 20px 50px;
}

</style>
